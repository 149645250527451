import * as React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
// import SaveIcon from '@material-ui/icons/Save';
// import RecentList from './RecentList';
import Search from '../matter/Search';
import AllMatterTab from '../matter/AllMatterTab';
import _ from 'lodash';
import FavouriteMatterTab from '../matter/FavouriteMatterTab';
import RecentMatterTab from '../matter/RecentMatterTab';
import { MatterLookUpData, MatterLookUpQueryParams, retrieveMatterLookUpData } from '../matter/MatterLookUpRepository';
import { showNotification } from '../../App';

export const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      '& > div': {
        padding: 0,
      }
    },
    title: {
      flex: 1,
      textAlign: 'center',
      flexGrow: 1,
      fontSize: '21px',
      fontWeight: 100,
      color: '#333333',
      lineHeight: '32px',
      margin: 0,
      fontFamily: 'Segoe UI'
    },
    content: {
      flex: 1,
      display: 'flex',
      flexFlow: 'column'
    },
    tabContent: {
      flex: 1,
      overflowY: 'auto',
      display: 'flex',
      flexFlow: 'column',
      '& .gridview > div': {
        flexFlow: 'column!important',
      },
    },
    tabs: {
      flexGrow: 1,
      maxWidth: 'none',
      flexBasis: 0,
      flexShrink: 1,
      minWidth: 0,
    },
    menu: {},
    searchContainer: {
      margin: '15px 0',
      // backgroundColor: '#f5f5f5'
    },
    spaceCreator: {
      width: '48px',
      height: '48px'
    },
  });

interface DialogProps extends WithStyles<typeof styles> {
    onClose: () => void;
    // tslint:disable-next-line: no-any
    onData: (data: any) => void;
    favMattersFirst?: boolean;
    sourceSystem: string;
    feeEarnerId?: string;
}

interface DialogStates {
  // tslint:disable-next-line:no-any
  tab: number;
  search: string;
  searchPlaceholder: string;
  // tslint:disable-next-line: no-any
  matterLookupList: any;
}

class TimeslipMatterPage extends React.Component<DialogProps, DialogStates> {

  constructor(props: DialogProps) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onMatterSelectedItem = this.onMatterSelectedItem.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onAllMatterTabChange = this.onAllMatterTabChange.bind(this);
    this.fetchMatterData = this.fetchMatterData.bind(this);
    this.onDataRetrieved = this.onDataRetrieved.bind(this);

    this.state = {
      tab: 2,
      search: '',
      searchPlaceholder: 'Search favourite matters',
      matterLookupList: [],
    };
    this.fetchMatterData();
  }

  UNSAFE_componentWillReceiveProps(nextProps: DialogProps, nextContext: DialogStates): void {
    // code here
  }

  render() {
    const { classes } = this.props;

    let infiniteScrollWrapper: JSX.Element;

    if (this.state.tab === 0) {
      infiniteScrollWrapper = (
        <RecentMatterTab
          search={this.state.search}
          onSelectedItem={this.onMatterSelectedItem}
          sourceSystem={this.props.sourceSystem}
          feeEarnerId={this.props.feeEarnerId}

        />
      );
    } else if (this.state.tab === 1) {
      infiniteScrollWrapper = (
        <AllMatterTab
          search={this.state.search}
          onSelectedItem={this.onMatterSelectedItem}
          onTabChange={this.onAllMatterTabChange}
          sourceSystem={this.props.sourceSystem}
          feeEarnerId={this.props.feeEarnerId}

        />
      );
    } else {
      infiniteScrollWrapper = (
        <FavouriteMatterTab
          search={this.state.search}
          onSelectedItem={this.onMatterSelectedItem}
          sourceSystem={this.props.sourceSystem}
          feeEarnerId={this.props.feeEarnerId}
        />
      );
    }

    return (
      <React.Fragment>
          <div className={classes.appBar} color="default">
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography className={classes.title}>Matter</Typography>
              <div className={classes.spaceCreator} />
            </Toolbar>
          </div>
          <div className={classes.content}>
            <div className={classes.searchContainer}>
              <Search 
                onSearch={this.onSearch} 
                placeholder={this.state.searchPlaceholder}
              />
            </div>
            <div className={classes.tabContent + ' tab-content'} id="tabContainer">
              {infiniteScrollWrapper}
            </div>
            <Paper square={true} className={classes.menu}>
              <Tabs
                value={this.state.tab}
                onChange={this.handleTabChange}
                textColor="primary"
              >
                <Tab
                  className={classes.tabs}
                  icon={<PhoneIcon />}
                  label="RECENTS"
                />
                <Tab
                  className={classes.tabs}
                  icon={<PersonPinIcon />}
                  label="All"
                />
                <Tab
                  className={classes.tabs}
                  icon={<FavoriteIcon />}
                  label="FAVORITES"
                />
              </Tabs>
            </Paper>
          </div>
      </React.Fragment>
    );
  }
  private fetchMatterData() {
    var matterLookUpQueryParams: MatterLookUpQueryParams = {
        offset: 0,
        first: 1,
        filter: this.state.search,
        typeFilter: "Favourite",
        statusFilter: "All",
        isExcludeCompleted: false,
        feeEarnerId: this.props.feeEarnerId
    };

    retrieveMatterLookUpData(matterLookUpQueryParams,
        true, // refresh data
        (data) => this.onDataRetrieved(data),
        // tslint:disable-next-line:no-any
        function (reason: any): void {
            showNotification(null, reason, 'error');
        });
  }

  private onDataRetrieved(data: MatterLookUpData) {
    // append the data if we already have some from previous loading
    const matterLookupListData = data.matterLookupList.matters;

    // Set the Total Record count for the search
    // const totalRecords = data.matterSummaryList.recordCount;

    // Current record count
    const currentRecordCount = matterLookupListData.length;
    if (currentRecordCount === 0) {
        this.setState({tab:0, searchPlaceholder: 'Search recent matters',})
    }



  }

  private handleClose() {
    if (this.props.onClose) {
        return this.props.onClose();
    }
  }

  private onSearch(value: string) {
    this.setState({
      search: value
    });
  }

  // tslint:disable-next-line: no-any
  private onMatterSelectedItem(matter: any) {
    if (this.props.onData) {
      this.props.onData(matter);
    }
  }

  // tslint:disable-next-line: no-any
  private onSelectItem = (matter: any) => () => {
    if (this.props.onData) {
      this.props.onData(matter);
    }
  }

  private handleSave() {
    if (this.props.onData) {
      this.props.onData('data');
    }
    if (this.props.onClose) {
      return this.props.onClose();
    }
  }

  private onAllMatterTabChange(placeholder: string) {
    this.setState({
      searchPlaceholder: placeholder
    });
  }

  private handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    let placeholder = '';
    switch (newValue) {
      case 0: {
        placeholder = 'Search recent matters';
        break;
      }
      case 1: {
        placeholder = 'Search all matters';
        break;
      }
      default: {
        placeholder = 'Search favourite matters';
        break;
      }
    }

    this.setState({
      tab: newValue,
      searchPlaceholder: placeholder,
    });

  }
}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(TimeslipMatterPage as any) as any;

// tslint:disable-next-line:no-any
export function getMatterTitlePrefix(sourceSystem: string, matter: any): string {
  if (sourceSystem.toLowerCase() === 'inprotech') {
    return matter.propertyLabel ? matter.propertyLabel : '';
  }

  return matter.matterCategory ? matter.matterCategory : '';

}
